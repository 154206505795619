:root {
  --footer-height: 60px;
  --value-10px: 10px;
  --value-20px: 20px;
  --value-30px: 30px;
  --footer-color: #565656;
  --page-background-color: #E7E7E7;
}

body, #root {
  /* height: 100vh; */
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.login-page {
  background-color: black;
}
.home-page {
  background-color: var(--page-background-color);
  width: 100%;
  height: 100%;
}

.login-box {
  max-width: 430px;
  background-color: black !important;
}

.footer {
  color: var(--footer-color);
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: var(--footer-height);
  min-height: var(--footer-height);
  line-height: 1em;
  background-color: #bbbbbb;
  padding-top: var(--value-10px);
  font-size: 0.9em;
}
.login-page .footer {
  background-color: #000000;
}
.footer-left {
  padding-left: var(--value-20px) !important;
}
.footer-center {
  text-align: center;
}
.footer-right {
  text-align: right;
  padding-right: var(--value-20px) !important;
}
.footer-right > a {
  color: var(--footer-color);
}

.nav-link {
  color: #FFFFFF !important;
  align-self: center;
}
.nav-link.active {
  font-weight: bold;
  font-size: 1.1em;
}

nav.bg-secondary {
  background-color: #44484c !important;
  border-bottom: 4px solid #f30303;
}

nav div:nth-child(2) {
  text-transform: uppercase;
    font-size: 0.7em;
    letter-spacing: 2px;
}


.grid-container {
  overflow: auto;/*necessario per aggiungere le scroll quando si supera la larghezza*/
  flex: 1 1 auto;/*necessario perchè estende la griglia se ha meno elementi della larghezza*/
} 
.facets {
  width: 300px;
  /* background-color: #FFFFFF; */
  flex: 0 0 auto;
  padding-top: var(--value-20px);
  color: #464646;
}
.facet {
  padding: 4px 0px 4px 0px;
  font-size: 0.85em;
  letter-spacing: 1px;
  padding-left: var(--value-10px);
  padding-right: var(--value-10px);
  cursor: pointer;
}
.facet > p {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.3em;
}
.photo {
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.10);
}
.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 130px));
  /* grid-gap: 15px; */
  column-gap: var(--value-30px);
  row-gap: var(--value-20px);
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: var(--value-10px);
}
.photo-grid-empty {
  margin: var(--value-30px);
  display: flex;
  justify-content: center;
}
.trial-banner {
  margin: var(--value-20px);
}
.trial-banner > a > img {
  max-width: 100%;
}
/* .photo-grid::after {
  content: '';
  flex: auto;
  display: block;
} */
.facet-mobile-header {
  display: none;
}
.facet-body {
  margin-bottom: 30px;
}
.applied-facets .mobile-facets-toggle-button {
  display: none;
}

.applied-facets {
  /* background-color: rgb(255, 0, 255); */
  /* line-height: var(--value-30px); */
  display: flex;
}

.applied-facets ul {
  margin-bottom: 0px;
}

.applied-facets ul li {
  display: inline-block;
    margin-right: 15px;
    /* height: var(--value-30px); */
    background-color: #ffffff;
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    font-size: 0.9em;
    font-weight: bold;
    margin-top: var(--value-10px);
    cursor: pointer;
}

.paginator {
  /* background-color: #3355FF; */
  margin-top: var(--value-30px);
  justify-content: center;
}
.page-item.active .page-link {
      background-color: #c3c3c3 !important;
      border-color: #00000000 !important;
}
.page-link {
  color: #858585 !important;
}

.homepage-content {
  /* background-color: blueviolet; */
  flex: 1 0 auto;
  width: 100%;
}

.home-page footer {
  position: unset;
  margin-top: var(--value-30px);
}

.loader {
  position: fixed;
  bottom: 0px;
  top: 0px;
  background-color: #ffffffbf;
  left: 0px;
  right: 0px;
  z-index: 1000;
}
.loader > div.spinner-border {
  width: 4rem;
  height: 4rem;
}

.photo {
  width: 130px;
  height: 130px;
  /* background-color: #ffa299; */
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.photo>img {
  width: 130px;
  height: 130px;
}
.favorite-button {
  top: 0px;
  right: 0px;
  position: absolute;
}
.favorite-button>img {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  margin-top: 4px;
}

.filter-entries {
  display: flex;
  flex-direction: column;
  margin-left: var(--value-10px);
  margin-right: var(--value-10px);
  font-size: 1em;
}

.filter-item {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 7px 0px 7px 0px;
  line-height: 1em;
}

.filter-item .bold {
  font-weight: bold;
}

.filter-item-cb {
  width: 30px;
}

.filter-item-count {
  margin-left: auto;
}
.back-to-grid {
  margin-left: auto;
}

.photo-detail-row {
  display: flex;
  justify-content: center;
  padding-left: var(--value-20px);
  padding-right: var(--value-20px);
}
.photo-detail-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0;
  justify-content: center;
  margin-top: var(--value-30px);
  margin-bottom: var(--value-30px);
}
.photo-detail-photo {
  flex: 1 0;
  max-width: 500px;
  /* border: 1px solid #aaa; */
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.10);
  border-radius: 5px;
  overflow: hidden;
  /* background-color: #334455; */
}
.photo-detail-right-col {
  display: flex;
  justify-content: center;
}

/*
commentato per gestire la libreria di zoom
.photo-detail-photo img {
  max-width: 100%;
  max-height: 100%;
} */

/*
  Questo è il fattore di zoom dell'immagine
*/
.photo-detail-photo > div > div > div:nth-child(2) > img {
  width: 1600px !important;
}
/* .photo-detail-children {
  width: 130px;
  height: 490px;
  margin-left: var(--value-20px);
  margin-right: var(--value-30px);
} */
.photo-detail-children {
  width: 100%;
  height: 150px;
  /* background-color: #3344FF; */
  overflow: auto;
  position: relative;
  margin-top: var(--value-10px);
  margin-left: 0px;
  margin-right: unset;
  /* display: flex;
  justify-content: center; */
}
.photo-detail-children .scroller {
  /* border: 1px solid #aaa; */
}
.photo-detail-children .scroller img {
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.10);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.photo-detail-children img {
  width: 130px;
}
.photo-detail-info {
  width: 400px;
  /* background-color: #9debd3; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--value-10px);
  margin-bottom: var(--value-30px);
}
.photo-detail-info-content {
  /* background-color: #ebe848; */
  display: grid;
  grid-template-areas: "a";
  column-gap: var(--value-30px);
  color: var(--footer-color);
}

.pdi-info {
  display: flex;
  border-bottom: 1px solid #aaa;
}
.pdi-label {
  font-size: 1em;
  font-weight: bold;
  width: 200px;
}
.pdi-actions {
  display: flex;
  max-width: 930px;
  width: 100%;
  justify-content: space-around;
  margin-bottom: var(--value-10px);
}
.pdi-download, .pdi-favorite {
  display: flex;
  justify-content: center;
}
.pdi-favorite > .favorite-button {
  position: unset;
  top: unset;
  right: unset;
  cursor: pointer;
}

.mobile-detail-controls {
  display: none !important;
}

.desktop-detail-controls {
  display: flex;
  justify-content: center;
}

.previous-control {
  text-align: end;
  padding-right: var(--value-30px) !important;
}
.next-control {
  padding-left: var(--value-30px) !important;
}
button.previous-control, button.next-control {
  border: 0px;
  background-color: unset;
  letter-spacing: 4px;
  font-size: 0.9em;
  outline: none !important;
}
button.previous-control.disabled, button.next-control.disabled {
  color: #c3c3c3;
}

.menu-mobile-location {
  display: none;
}

/**
MOBILE
**/

@media screen and (max-width: 576px) {

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .login-logo {
    width: 100px;
  }

  .footer {
    font-size: 0.8em;
  }

  .facets {
    position: fixed;
    width: 100%;
    top: 0px;
    bottom: 0px;
    z-index: 100;
    display: none;
    overflow-y: auto;
    background-color: var(--page-background-color);
  }

  .facets-header {
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: bold;
    align-self: flex-end;
    margin-bottom: 0px;;
  }

  .close-btn {
    border-width: 0px !important;
    margin-right: var(--value-10px);
  }

  .facet-mobile-header {
    display: flex;
    margin-bottom: var(--value-10px);
  }

  .applied-facets > ul {
    overflow-x: auto;
    /* height: var(--value-30px); */
    white-space: nowrap;
    align-self: center;
  }

  .applied-facets > ul li {
    margin-top: 0px;
  }

  .applied-facets .mobile-facets-toggle-button {
    display: unset;
    margin-right: var(--value-10px);
    border-width: 0px;
    height: 48px;
    width: 48px;
  }


  .photo-detail-row {
    padding-left: 0px;
    padding-right: 0px;
  }

  .photo-detail-container {
    flex-direction: column;
    margin-top: unset;
  }

  .photo-detail-info {
    width: 100%;
  }

  .photo-detail-photo {
    flex: unset;
    max-width: unset;
    background-color: #334455;
    width: 100%;
    overflow: unset;
    border: unset;
    border-radius: unset;
}
  .photo-detail-children {
    width: 100%;
    height: 130px;
    /* background-color: #3344FF; */
    overflow: auto;
    position: relative;
    margin-top: var(--value-10px);
    margin-left: 0px;
    margin-right: unset;
    /* display: flex;
    justify-content: center; */
  }
  
  .photo-detail-children img {
    width: 130px;
    height: 130px;
    /* background-color: #3344FF; */
  }

  .photo-detail-children .scroller {
    position: absolute;
    display: flex;
    border-radius: unset;
    border: unset;
  }

  .photo-detail-info-content {
    grid-template-areas: "a";
    width: 100%;
    padding-left: var(--value-10px);
    padding-right: var(--value-10px);
  }
  
  .pdi-info {
    display: grid;
    grid-template-areas: "a";
  }
  .pdi-label {
    width: unset;
  }

  .mobile-detail-controls {
    display: flex !important;
    margin-top: var(--value-20px);
    margin-bottom: var(--value-10px);
    justify-content: space-around;
  }

  .previous-control {
    padding-right: unset !important;
  }
  .next-control {
    padding-left: unset !important;
  }

  .desktop-detail-controls {
    display: none !important;
  }

  .paginator {
    overflow-x: auto;
  }

  .menu-mobile-location {
    display: unset;
    color: white;
    font-size: 1.1em;
  }

  nav div:nth-child(2) {
    margin-top: 40px;
  }

  nav div:nth-child(2) .nav-link {
    display: inline-block;
  }

}

  